import GlobalStyled from "./styles/GlobalStyled.js";
import Home from "./pages/Home/Home.jsx";

function App() {
  return (
    <>
    <GlobalStyled />
      <Home />  
    </>
  );
}

export default App;
