import Main from "../../components/Main/Main"

const Home = () => {
  return (
    <> 
      <Main />
    </>
  )
};

export default Home;